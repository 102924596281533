@import '../../styles/colors';
@import '../../styles/variables';

.mainheader {
  width: 100%;
  max-width: $container-max-width;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  &_logo {
    height: 60px;
    margin-right: 15px;
    img {
      height: 100%;
    }
  }
  &_actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    &_menu {
      display: flex;
      flex-direction: row;
      align-items: center;
      min-width: max-content;
    }
    &-menuicon {
      display: none;
      height: 20px;
      margin-left: 20px;
      img {
        height: 100%;
      }
    }
  }
}

@media (max-width: $mobile-break) {
  .mainheader {
    &_actions {
      &_menu {
        display: none;
      }
      &-menuicon {
        display: block;
      }
    }
  }
  .mobile-menu {
    position: fixed;
    z-index: 100;
    background-color: #000000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
