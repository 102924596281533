@import '../../../styles/colors';

.countryModal {
  background-color: $color-secondary;
  border: 3px solid $color-light;
  border-radius: 10px;
  padding: 10px 15px 5px 20px;
  &_country {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 5px;
    border-bottom: 2px solid $color-complementary;
    margin-bottom: 10px;
    color: $color-complementary;
    font-family: 'OpenSans-Bold';
    &-prize {
      height: 60px;
      margin-right: 10px;
      img {
        height: 100%;
      }
    }
  }

  &_items {
    overflow-y: scroll;
    max-height: 200px;
    &_item {
      display: flex;
      font-size: 12px;
      text-transform: capitalize;
      margin-bottom: 10px;
      align-items: center;
      cursor: pointer;
      // overflow: hidden;
      // text-overflow: ellipsis;
      &-person {
        height: 22px;
        margin-right: 10px;
        img {
          height: 100%;
        }
      }
    }
  }
}
