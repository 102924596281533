$item-height: 25px;

.languageSelector {
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
  &_selected {
    cursor: pointer;
    display: flex;
    &_image {
      height: $item-height;
    }
  }

  &_dropdown {
    display: flex;
    flex-direction: column;
    text-align: center;
    position: absolute;
    top: $item-height;
    &_item {
      cursor: pointer;
      border: none;
      height: $item-height;
      &_icon {
        height: $item-height;
      }
    }
  }
}
