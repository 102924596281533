@import '../../../styles/colors';

.certificatesModal {
  position: fixed;
  z-index: 100;
  background-color: #00000090;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  &_container {
    background-color: $color-secondary;
    border: 3px solid $color-light;
    border-radius: 10px;
    padding: 10px 20px 5px 20px;
    display: flex;
    flex-direction: column;
    &_certificate {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-bottom: 5px;
      border-bottom: 2px solid $color-complementary;
      margin-bottom: 10px;
      color: $color-complementary;
      &-prize {
        height: 60px;
        margin-right: 10px;
        img {
          height: 100%;
        }
      }
    }
    &_logo {
      width: 100%;
      align-self: center;
      max-width: 300px;
      max-height: 200px;
      overflow-y: hidden;
      margin-bottom: 10px;
      img {
        width: 100%;
      }
    }

    &_info {
      font-size: 12px;
      paddding: 10px;
      margin-bottom: 10px;
      &_data {
        // padding-top: 5px;
        // padding-left: 10px;
        display: flex;
        flex-direction: row;
        &-icon {
          width: 20px;
          margin-right: 10px;
          padding-bottom: 5px;
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
