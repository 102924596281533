@import '../../styles/colors';
@import '../../styles/variables';

.certificates {
  position: relative;
  &_globe {
  }
  &_countries-select {
    display: none;
  }
  &_countries-menu {
    position: absolute;
    top: 0;
    bottom: 0;
    // left: 40%;
    // pointer-events: auto;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &_country-modal {
    position: absolute;
    // pointer-events: auto;
    left: 50%;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 80px;
  }
}

@media (max-width: $mobile-break) {
  .certificates {
    &_countries-select {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 10px;
    }
    &_countries-menu {
      display: none;
    }
    &_country-modal {
      position: static;
      padding: 0 10px;
      margin-bottom: 30px;
    }
  }
}
