@import 'colors';

// Estilos personalizados globales, usar prefijo g-

/* Tamaño del scroll */
.g-scroll::-webkit-scrollbar {
  width: 8px;
}

/* Estilos barra (thumb) de scroll */
.g-scroll::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 0;
}

.g-scroll::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}

.g-scroll::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Estilos track de scroll */
.g-scroll::-webkit-scrollbar-track {
  background: $color-secondary;
  border-radius: 0;
}

.g-scroll::-webkit-scrollbar-track:hover,
.g-scroll::-webkit-scrollbar-track:active {
  background: $color-secondary;
}

.g-bold {
  font-family: 'OpenSans-Bold';
}
