@import '../../styles/colors';
@import '../../styles/variables';

.main {
  &_header {
    background-color: black;
    position: absolute;
    right: 0;
    left: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
  }
  &_content {
    padding-top: 80px;
  }
}

@media (max-width: $mobile-break) {
  .main {
    &_header {
      position: fixed;
    }
  }
}
