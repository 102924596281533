@import '../../styles/colors';
@import '../../styles/variables';

.home {
  &_first {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
    margin-bottom: 160px;
    &_container {
      background-image: url('../../assets/images/home_photo.jpg');
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: bottom;
      width: 100%;
      max-width: $container-max-width;
      &_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 40%;
        text-align: center;
        padding: 40px 0;
        &_master {
          margin-bottom: 20px;
          img {
            width: 100%;
            max-width: 240px;
          }
        }
        &_prize {
          margin-top: 20px;
          img {
            width: 30%;
          }
        }
      }
    }
  }

  &_second {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
    margin-bottom: 40px;
    &_container {
      width: 100%;
      max-width: $container-max-width;
      &_header {
        display: flex;
        flex-direction: row;
        &_network {
          flex: 1;
          text-align: center;
          padding: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
          }
        }
        &_text {
          font-size: 22px;
          line-height: 35px;
          flex: 1;
          padding: 30px 10px;
          div {
            margin-bottom: 20px;
          }
        }
      }
      &_welcome-photo {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
  }

  &_third {
    display: flex;
    flex-direction: column;
    align-items: center;
    &_container {
      width: 100%;
      max-width: $container-max-width;
      &_header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 60px;
        &_master {
          width: 240px;
          img {
            width: 100%;
          }
        }
        h1 {
          font-size: 52px;
        }
      }
      &_bars {
        position: relative;
        top: -60px;
        padding: 0 60px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 0 10px;
        &_bar {
          width: 20%;
          padding: 0 10px;
          img {
            width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: $mobile-break) {
  .home {
    &_first {
      margin-bottom: 40px;
      &_container {
        background-size: auto 100%;
        &_content {
          width: 100%;
          background-color: #00000080;
        }
      }
    }
    &_second {
      &_container {
        &_header {
          flex-direction: column;
          &_text {
            font-size: 18px;
            line-height: 25px;
            flex: 1;
            padding: 10px 20px;
            div {
              margin-bottom: 10px;
            }
          }
        }
        &_photos {
          width: 100%;
          display: flex;
          justify-content: center;
          height: 80px;
          &-photo {
            height: 100%;
            img {
              width: auto;
              height: 100%;
            }
          }
        }
      }
    }
    &_third {
      &_container {
        width: 100%;
        &_header {
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 0;
          h1 {
            font-size: 24px;
            text-align: center;
          }
        }
        &_bars {
          top: 0;
          padding: 0;
          &_bar {
            width: 25%;
            padding: 0 5px;
          }
        }
      }
    }
  }
}
