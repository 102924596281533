@import '../../../styles/colors';
@import '../../../styles/variables';

.menuitem {
  display: flex;
  flex-direction: row;
  align-items: center;
  &_button {
    cursor: pointer;
    &_label {
      color: $color-light;
      font-size: 14px;
      padding: 2px 5px;
      > a {
        color: $color-light;
        text-decoration: none;
      }
    }
    &.selected {
      background-color: $color-normal;
      border-radius: 5px;
    }
  }
  &_divisor {
    height: 15px;
    padding-left: 5px;
    margin-right: 5px;
    border-right: 1px solid $color-light;
  }
}

@media (max-width: $mobile-break) {
  .menuitem {
    &_button {
      text-align: center;
      margin: 20px 0;
      &_label {
        font-size: 20px;
      }
    }
    &_divisor {
      display: none;
    }
  }
}
