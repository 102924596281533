@import '../../../styles/colors';

.countriesMenu {
  max-height: 80%;
  width: 220px;
  min-width: 180px;
  &_title {
    background-color: $color-normal;
    padding: 10px;
    font-size: 18px;
    border-radius: 0 10px 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &_arrow {
      height: 20px;
    }
  }
  &_items {
    background-color: $color-secondary;
    width: 200px;
    min-width: 180px;
    height: 400px;
    color: $color-light;
    cursor: pointer;
    overflow-y: scroll;
    border-top: none;
    &_country {
      padding: 5px 0;
      line-height: 18px;
      padding: 10px;
      &.selected {
        background-color: red;
      }
    }
  }
}
