@import '../../styles/colors';
@import '../../styles/variables';

.mainmenu {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

@media (max-width: $mobile-break) {
  .mainmenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 0;
    height: 100%;
  }
}
