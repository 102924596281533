@import '../../styles/colors';
@import '../../styles/variables';

.contact {
  display: flex;
  justify-content: center;
  height: 100%;
  &_container {
    width: 850px;
    margin-top: 40px;
    background-color: black;
    display: flex;
    font-size: 14px;
    margin-bottom: 20px;
    &_left {
      flex: 1;
      padding: 0 20px;
      background-image: url('../../assets/images/contact_photo.png');
      background-position: bottom 0 left 20px;
      background-repeat: no-repeat;
      background-size: 240px auto;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      text-align: right;
      &_title {
        font-size: 42px;
        padding-bottom: 10px;
      }
      &_text {
        padding-left: 60px;
        margin-bottom: 40px;
      }
      a {
        text-decoration: none;
        color: white;
      }

      &_item {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin: 5px 0;
        span {
          margin-left: 10px;
        }
      }
      &_social {
        display: flex;
        margin-top: 40px;
        cursor: pointer;
      }
      &_icon {
        width: 36px;
        margin-left: 10px;
        img {
          width: 100%;
        }
      }
    }
    &_right {
      flex: 1;
      padding: 20px 60px 20px 20px;
      input,
      textarea {
        font-family: 'OpenSans-Regular';
        font-size: 18px;
        background-color: black;
        width: 95%;
        color: white;
        border: 2px solid $color-normal;
        padding: 5px 10px;
        margin-bottom: 10px;
      }
      #submit-button {
        width: max-content;
        border: none;
        border-bottom: 2px solid $color-dark;
        text-align: left;
        text-transform: uppercase;
        cursor: pointer;
        padding: 5px;
      }
      &_logo {
        margin-top: 20px;
        height: 100px;
      }
    }
  }
}

@media (max-width: $mobile-break) {
  .contact {
    padding: 0 10px;
    &_container {
      width: 100%;
      flex-direction: column;
      margin-top: 20px;
      border-width: 0;
      border-image-source: none;
      &_left {
        background-size: 180px auto;
      }
      &_right {
        padding: 20px;
      }
    }
  }
}
