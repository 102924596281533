@import '../../styles/colors';
@import '../../styles/variables';

.mainfooter {
  width: 100%;
  height: 240px;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  font-size: 20px;
  &_social {
    display: flex;
    margin-bottom: 20px;
    &-footericon {
      cursor: pointer;
      height: 60px;
      padding: 0 5px;
      img {
        height: 100%;
      }
    }
  }
}
